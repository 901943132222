<template>
	<div class="pa-3">
		<v-form ref="form" v-model="valid">
			<v-textarea
				v-model="content"
				:label="$t('newsfeed.content')"
				:placeholder="$t('newsfeed.whatAreYouThinking')"
				:rows="1"
				:rules="rules.content"
				:counter="contentMaxLength"
				auto-grow
				outlined
				rounded
				hide-details="auto"
				class="pb-0"
			/>
		</v-form>
		<div class="mx-auto d-flex align-center justify-center flex-wrap pt-0 pb-1">
			<v-btn class="px-4" color="primary" large rounded :loading="loading" @click="callPublishComment()">
				{{ $t('newsfeed.reply') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'FeedPostNewComment',
	props: {
		post: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			valid: false,
			content: '',
			rules: {
				content: [
					(v) => !!v || this.$t('rules.required'),
					(v) => (v && v.length <= this.contentMaxLength) || this.$t('rules.length', { length: this.contentMaxLength }),
				],
			},
		}
	},
	computed: {
		contentMaxLength() {
			return 1024 * 10
		},
	},
	methods: {
		callPublishComment() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.loading = true
			this.publishComment({ post: this.post, content: this.content })
				.then(({ success, comment }) => {
					if (success) {
						this.content = ''
						this.$emit('add', comment)
						this.$refs.form.resetValidation()
					}
				})
				.then(() => {
					this.loading = false
				})
		},
		...mapActions('feed', ['publishComment']),
	},
}
</script>
